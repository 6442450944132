const routingTable = [
    { path: '/', filePath: 'Blank', name: 'Home', exact: true },
    { path: '/ChangeUser', filePath: 'ChangeUser', name: 'Change User', exact: true },

    /* Reports */
    { path: '/BMS/ShipmentReport', filePath: 'Report/ShipmentReport', name: 'Shipment (Recharts)', funcId: 'm_analytics_shipment' },
    { path: '/BMS/ShipmentReportPBIDirectQuery', filePath: 'Report/ShipmentReportPBI', name: 'Shipment (DirectQuery)', funcId: 'm_analytics_shipment_pbi_direct_query' },
    { path: '/BMS/ShipmentReportPBIDataImport', filePath: 'Report/ShipmentReportPBI', name: 'Sea & Other Shipment', funcId: 'm_analytics_shipment_pbi_data_import' },
    { path: '/BMS/ShipmentReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Sea & Other Shipment 2', funcId: 'm_analytics_shipment2' },
    { path: '/BMS/CarrierReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Carrier', funcId: 'm_analytics_carrier' },
    { path: '/BMS/VendorReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Vendor', funcId: 'm_analytics_vendor' },
    { path: '/BMS/InvoiceGPPBI', filePath: 'Report/ShipmentReportPBI', name: 'Invoice GP', funcId: 'm_analytics_inv_gp_pbi' },
    { path: '/BMS/ProfitabilityReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Profitability', funcId: 'm_analytics_profitability' },
    { path: '/BMS/ProfitabilityReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Profitability 2', funcId: 'm_analytics_profitability2' },
    { path: '/BMS/SalesProfitabilityReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Sales Profitability', funcId: 'm_analystics_sales_profitability' },
    { path: '/BMS/SalesProfitabilityReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Sales Profitability 2', funcId: 'm_analystics_sales_profitability2' },
    { path: '/BMS/CarrierMQC', filePath: 'Report/ShipmentReportPBI', name: 'Carrier MQC', funcId: 'm_analytics_carrier_mqc' },
    { path: '/BMS/CarrierMQCShapiro', filePath: 'Report/ShipmentReportPBI', name: 'Carrier MQC (Shapiro)', funcId: 'm_analytics_carrier_mqc_shapiro' },
    { path: '/BMS/AgentReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Sea & Other Agent', funcId: 'm_analytics_agent' },
    { path: '/BMS/AgentReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Sea & Other Agent 2', funcId: 'm_analytics_agent2' },
    { path: '/BMS/OperationReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Operation', funcId: 'm_analytics_operation' },
    { path: '/BMS/OperationAlertPBI', filePath: 'Report/ShipmentReportPBI', name: 'Operation Alert', funcId: 'm_analytics_operation_dashboard' },
    { path: '/BMS/AirShipmentReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Air Shipment', funcId: 'm_analytics_air_shipment' },
    { path: '/BMS/AirAgentReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Air Agent', funcId: 'm_analytics_air_agent' },
    { path: '/BMS/AirShipmentReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Air Shipment 2', funcId: 'm_analytics_air_shipment2' },
    { path: '/BMS/AirAgentReportPBI2', filePath: 'Report/ShipmentReportPBI', name: 'Air Agent 2', funcId: 'm_analytics_air_agent2' },
    { path: '/BMS/BookingPBI', filePath: 'Report/ShipmentReportPBI', name: 'Booking', funcId: 'm_analytics_booking_analytics' },

    { path: '/BMS/CarrierPerformance', filePath: 'Report/ShipmentReportPBI', name: 'Carrier Performance', funcId: 'm_analytics_carrier_kpi' },
    { path: '/BMS/CarrierPerformanceSummary', filePath: 'Report/ShipmentReportPBI', name: 'Carrier Performace (Summary)', funcId: 'm_analytics_carrier_summary' },
    { path: '/BMS/CO2eEmissionReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'CO2e Emission', funcId: 'm_analytics_co2e_emission' },
    { path: '/BMS/ProductivityReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Productivity', funcId: 'm_analytics_productivity' },

    { path: '/BMS/InsightTrackerReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Insight Tracker', funcId: 'm_analytics_insight_tracker' },
    { path: '/BMS/WeblogTrackerReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Weblog Tracker', funcId: 'm_analytics_weblog_tracker' },

    { path: '/BMS/OperationAlertDashboard', filePath: 'OperationAlert/OperationAlertDashboard', name: 'Operation Alert 2', funcId: 'm_operation_alert_dashboard' },

    //for deom
    { path: '/BMS/ManorReportPBI', filePath: 'Report/ShipmentReportPBI', name: 'Manor', funcId: 'm_analytics_manor' },
    //end for demo

    /* Advance search booking */
    { path: '/BMS/enhance_booking', filePath: 'Dashboard', name: 'Booking', exact: true },

    { path: '/BMS/enhance_booking/shipping_order/new', filePath: 'ShippingOrder', name: 'Create New Manual Booking', funcId: 'create_booking' },
    { path: '/BMS/enhance_booking/shipping_order/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order' },
    { path: '/BMS/enhance_booking/shipping_order_copy/:so_no', filePath: 'ShippingOrder', name: 'Copy Shipping Order' },
    { path: '/BMS/enhance_booking/e_booking/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order' },

    { path: '/BMS/enhance_booking/search_booking/', filePath: 'SearchBookingEnhance', name: 'Search Booking', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/new_e_booking/', filePath: 'SearchBookingEnhance', name: 'New SIS/OMS e-Booking Pending Creation', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/amend_e_booking/', filePath: 'SearchBookingEnhance', name: 'Amended e-Booking Pending', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/cancel_e_booking/', filePath: 'SearchBookingEnhance', name: 'Cancelled e-Booking Pending', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/new_manual_booking/', filePath: 'SearchBookingEnhance', name: 'New BMS Booking Pending Confirmation', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/booking_confirmed/', filePath: 'SearchBookingEnhance', name: 'Booking Confirmed', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/reuse_booking/', filePath: 'SearchBookingEnhance', name: 'Reuse Booking', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/booking_cancelled/', filePath: 'SearchBookingEnhance', name: 'Booking Cancelled', exact: true, funcId: 'booking' },
    { path: '/BMS/enhance_booking/booking_pending_si/', filePath: 'SearchBookingEnhance', name: 'Booking Pending Submit SI', exact: true, funcId: 'booking' },

    { path: '/BMS/enhance_booking/:search_state/shipping_order/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order' },
    { path: '/BMS/enhance_booking/:search_state/shipping_order_copy/:so_no', filePath: 'ShippingOrder', name: 'Copy Shipping Order' },
    { path: '/BMS/enhance_booking/:search_state/e_booking/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order' },
    
    
    
    { path: '/BMS/enhance_booking_approval', filePath: 'Dashboard', name: 'Booking Approval', exact: true, funcId: 'booking_approval' },

    { path: '/BMS/enhance_booking_approval/shipping_order/new', filePath: 'ShippingOrder', name: 'Create Booking', funcId: 'create_booking' },
    { path: '/BMS/enhance_booking_approval/shipping_order/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order' },

    { path: '/BMS/enhance_booking_approval/search_booking_approval/', filePath: 'SearchBookingEnhance', name: 'Search Booking Approval', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/pending_for_approval', filePath: 'SearchBookingEnhance', name: 'Booking Confirmed Pending for Booking Approval', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/request_approval', filePath: 'SearchBookingEnhance', name: 'Request For Approval', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/pending_customer_approval', filePath: 'SearchBookingEnhance', name: 'Pending For Customer Approval', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/booking_approved', filePath: 'SearchBookingEnhance', name: 'Booking Approved', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/booking_rejected', filePath: 'SearchBookingEnhance', name: 'Booking Rejected', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/booking_advice', filePath: 'SearchBookingEnhance', name: 'Booking Advice', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/booking_approval_updated_by_mr', filePath: 'SearchBookingEnhance', name: 'Booking Approval last updated by M+R', exact: true, funcId: 'booking_approval' },
    { path: '/BMS/enhance_booking_approval/booking_approval_updated_by_customer', filePath: 'SearchBookingEnhance', name: 'Booking Approval last updated by Customer', exact: true, funcId: 'booking_approval' },

    { path: '/BMS/enhance_booking_approval/:search_state/shipping_order/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order', funcId: 'booking_approval' },
    
    /* consolidation section */
    { path: '/BMS/consolidation', filePath: 'Dashboard', name: 'Consolidation', exact: true, funcId: 'consolidation' },

    { path: '/BMS/consolidation/consolidation_management/new', filePath: 'ConsolManagement/Consolidation', name: 'Create New Consol Plan', funcId: 'create_consolidation' },
    { path: '/BMS/consolidation/consolidation_management/:consolidation_no', filePath: 'ConsolManagement/Consolidation', name: 'Consolidation' },
    { path: '/BMS/consolidation/search_consolidation', filePath: 'SearchConsolidationEnhance', name: 'Search Consol Plan', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/request_consolidation_approval', filePath: 'SearchConsolidationEnhance', name: 'Request For Consol Approval', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/pending_consolidation_creation', filePath: 'SearchBookingEnhance', name: 'Pending for Consol Plan Creation', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/pending_customer_consolidation_approval', filePath: 'SearchConsolidationEnhance', name: 'Pending for Customer Consol Approval', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/consolidation_approved', filePath: 'SearchConsolidationEnhance', name: 'Consol Plan Approved', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/consolidation_rejected', filePath: 'SearchConsolidationEnhance', name: 'Consol Plan Rejected', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/consolidation_advice', filePath: 'SearchConsolidationEnhance', name: 'Consol Plan Advice', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/amended_booking_pending', filePath: 'SearchConsolidationEnhance', name: 'Amended Booking Pending (in Consol)', exact: true, funcId: 'consolidation' },
    { path: '/BMS/consolidation/load_plan_pending_allocation', filePath: 'SearchConsolidationEnhance', name: 'Load Plan Pending Allocation', exact: true, funcId: 'consolidation' },    

    { path: '/BMS/consolidation/:search_state/shipping_order/:so_no', filePath: 'ShippingOrder', name: 'Shipping Order', funcId: 'consolidation' },

    { path: '/BMS/consolidation/:search_state/consolidation_management/:consolidation_no', filePath: 'ConsolManagement/Consolidation', name: 'Consolidation' },
    { path: '/BMS/consolidation/:search_state/consolidation_management_copy/:consolidation_no', filePath: 'ConsolManagement/Consolidation', name: 'Copy Consolidation' },    

    /* setting section */
    { path: '/BMS/Setting', filePath: 'Blank', name: 'Setting', exact: true },
    { path: '/BMS/Setting/user_profile', filePath: 'Admin/UserProfile', name: 'User Profile' },
    { path: '/BMS/Setting/update_user_password', filePath: 'ChangePassword', name: 'Change Password' },
    /* template library */
    { path: '/BMS/Setting/TMPLIB/TemplateSearch', filePath: 'TemplateLibrary/UserTemplateSearch', name: 'Template Library', exact: true, funcId: 'tmplib_base' },
    { path: '/BMS/Setting/TMPLIB/TemplateSearch/TemplateDetails/new', filePath: 'TemplateLibrary/TmplibEntry', name: 'Create Template', exact: true, funcId: 'tmplib_edit' },
    { path: '/BMS/Setting/TMPLIB/TemplateSearch/TemplateDetails/new/:tmplib_view', filePath: 'TemplateLibrary/TmplibEntry', name: 'Create Template', exact: true, funcId: 'tmplib_edit' },
    { path: '/BMS/Setting/TMPLIB/TemplateSearch/TemplateDetails/:tmplib_id', filePath: 'TemplateLibrary/TmplibEntry', name: 'Template', exact: true, funcId: 'tmplib_base' },
    { path: '/BMS/Setting/TMPLIB/TemplateSearch/TemplateEdit/:tmplib_id', filePath: 'TemplateLibrary/TmplibEntry', name: 'Template', exact: true, funcId: 'tmplib_edit' },
    { path: '/TMPLIB/AddTemplate/:share_code', filePath: 'TemplateLibrary/TmplibEntry', name: 'Template', exact: true, funcId: 'tmplib_base' },

    /*Notification Center*/
    { path: '/BMS/NotificationCenter', filePath: 'Blank', name: 'Notification Center', exact: true },
    { path: '/BMS/NotificationCenter/search_email_notification/', filePath: 'NotificationCenter/SearchEmailNotification', name: 'Manage Email Notification', exact: true, funcId: 'admin' },
    { path: '/BMS/NotificationCenter/search_email_notification/new', filePath: 'NotificationCenter/NotificationSettingDetail', name: 'Create Notification Setting', funcId: 'admin' },
    { path: '/BMS/NotificationCenter/search_email_notification/:id?', filePath: 'NotificationCenter/NotificationSettingDetail', name: 'Notification Setting Detail', funcId: 'admin' },

    /*Notification*/
    { path: '/BMS/Notification/masterSetting/', filePath: 'Notification/NotificationMasterSetting' },
    //{ path: '/BMS/Notification/masterList/', filePath: 'Notification/NotificationMasterList' },

    /*SOPMS*/
    { path: '/SOPMS/SOPMSSearch/:section_code', filePath: 'TemplateLibrary/UserTemplateSearch', name: 'SOPMS', exact: true, funcId: 'tmplib_base' },
    { path: '/SOPMS/SOPMSSearch/SOPMSDetails/new', filePath: 'TemplateLibrary/TmplibEntry', name: 'Create SOPMS', exact: true, funcId: 'tmplib_edit' },
    { path: '/SOPMS/SOPMSSearch/SOPMSDetails/:tmplib_id', filePath: 'TemplateLibrary/TmplibEntry', name: 'SOPMS', exact: true, funcId: 'tmplib_base' },
    { path: '/SOPMS/SOPMSSearch/SOPMSEdit/:tmplib_id', filePath: 'TemplateLibrary/TmplibEntry', name: 'SOPMS', exact: true, funcId: 'tmplib_edit' },
    { path: '/SOPMS/routing_template/search_routing_template/routing_template_detail/new', filePath: 'TemplateLibrary/RoutingTemplateDetail', name: 'Create New Routing Template', funcId: 'create_routing_template' },
    { path: '/SOPMS/routing_template/search_routing_template/routing_template_detail/:routing_template_id', filePath: 'TemplateLibrary/RoutingTemplateDetail', name: 'Routing Template' },
    { path: '/SOPMS/routing_template/search_routing_template', filePath: 'TemplateLibrary/SearchRoutingTemplate', name: 'Routing Template', exact: true, funcId: 'routing_template' },

    /*Sequence Rule*/
    { path: '/BMS/Admin/SEQ/SequenceSearch', filePath: 'Sequence/SearchSequenceRule', name: 'Search Sequence Rule', exact: true, funcId: 'seq_base' },
    { path: '/BMS/Admin/SEQ/SequenceSearch/sequence_rule_details/:mst_seq_rule_id', filePath: 'Sequence/SequenceRuleDetails', name: 'Sequence Rule', funcId: 'seq_base' },
    { path: '/BMS/Admin/SEQ/sequence_rule_details/:mst_seq_rule_id', filePath: 'Sequence/SequenceRuleDetails', name: 'Sequence Rule', funcId: 'seq_base' },

    /* others section */
    { path: '/BMS/compareso', filePath: 'CompareSO', name: 'Compare SO' },

    /* admin section */
    { path: '/BMS/Admin', filePath: 'Blank', name: 'Admin', exact: true },
    //{ path: '/BMS/Admin/party_maintenance', filePath: 'Admin/SearchParty', name: 'Party Maintenance', exact: true, funcId: 'admin' },
    //{ path: '/BMS/Admin/party_maintenance/:party_id', filePath: 'Admin/Party', name: 'Party', funcId: 'admin' },
    { path: '/BMS/Admin/party_group_maintenance', filePath: 'Admin/SearchPartyGroup', name: 'Party Group Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/party_group_maintenance/new', filePath: 'Admin/PartyGroup', name: 'Create Party Group', funcId: 'admin' },
    { path: '/BMS/Admin/party_group_maintenance/:party_group_id?', filePath: 'Admin/PartyGroup', name: 'Party Group', funcId: 'admin' },
    { path: '/BMS/Admin/tradelane_maintenance', filePath: 'Admin/SearchTradeLane', name: 'TradeLane Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/tradelane_maintenance/new', filePath: 'Admin/TradeLane', name: 'Create TradeLane', funcId: 'admin' },
    { path: '/BMS/Admin/tradelane_maintenance/:tradelane_id', filePath: 'Admin/TradeLane', name: 'TradeLane', funcId: 'admin' },
    { path: '/BMS/Admin/sailing_schedule_maintenance', filePath: 'Master/SearchSailingSchedule', name: 'Sailing Schedule Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/sailing_schedule_maintenance/new', filePath: 'Master/SailingSchedule', name: 'Create Sailing Schedule', funcId: 'admin' },
    { path: '/BMS/Admin/sailing_schedule_maintenance/:sailing_schedule_id?', filePath: 'Master/SailingSchedule', name: 'Sailing Schedule', funcId: 'admin' },
    { path: '/BMS/Admin/rate_maintenance/new', filePath: 'Master/Rate', name: 'Create Rate', funcId: 'admin' },
    { path: '/BMS/Admin/rate_maintenance/:source_key?', filePath: 'Master/Rate', name: 'Rate', funcId: 'admin' },
    { path: '/BMS/Admin/user_maintenance', filePath: 'Admin/SearchUser', name: 'User Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/user_maintenance/new', filePath: 'Admin/UserMaintenanceDetail', name: 'Create User', funcId: 'admin' },
    { path: '/BMS/Admin/user_maintenance/:user_uid?', filePath: 'Admin/UserMaintenanceDetail', name: 'User', funcId: 'admin' },
    { path: '/BMS/Admin/user_registration_maintenance', filePath: 'Admin/SearchUserRegistration', name: 'User Registration Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/user_registration_maintenance/:user_registration_id?', filePath: 'Admin/UserRegistrationMaintenanceDetail', name: 'User Registration', funcId: 'admin' },
    { path: '/BMS/Admin/warehouse_maintenance', filePath: 'Master/SearchWarehouse', name: 'Warehouse Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/warehouse_maintenance/new', filePath: 'Master/Warehouse', name: 'Create Warehouse', funcId: 'admin' },
    { path: '/BMS/Admin/warehouse_maintenance/:party_code_id?', filePath: 'Master/Warehouse', name: 'Warehouse', funcId: 'admin' },
    { path: '/BMS/Admin/carrier_maintenance', filePath: 'Master/SearchCarrier', name: 'Carrier Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/carrier_maintenance/new', filePath: 'Master/Carrier', name: 'Create Carrier', funcId: 'admin' },
    { path: '/BMS/Admin/carrier_maintenance/:id?', filePath: 'Master/Carrier', name: 'Carrier', funcId: 'admin' },
    { path: '/BMS/Admin/sys_notice_maintenance', filePath: 'Admin/SearchSysNotice', name: 'System Notice Maintenance', exact: true, funcId: 'admin' },
    { path: '/BMS/Admin/sys_notice_maintenance/new', filePath: 'Admin/SysNoticeDetail', name: 'Create System Notice', funcId: 'admin' },
    { path: '/BMS/Admin/sys_notice_maintenance/:uid?', filePath: 'Admin/SysNoticeDetail', name: 'System Notice', funcId: 'admin' },

    /* party section */
    { path: '/BMS/party', filePath: 'Dashboard', name: 'Party', exact: true },
    { path: '/BMS/party/search_party', filePath: 'PartyManagement/SearchParty', name: 'Search Party', exact: true, funcId: 'party' },
    { path: '/BMS/party/pending_party_approval', filePath: 'PartyManagement/SearchParty', name: 'Party Pending For Approval', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_maintenance/new', filePath: 'PartyManagement/Party', name: 'Create New Party', funcId: 'party' },
    { path: '/BMS/party/pending_party_management_approval', filePath: 'PartyManagement/SearchParty', name: 'Party Pending For Management Approval', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_management_approved', filePath: 'PartyManagement/SearchParty', name: 'Management Approved', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_approved', filePath: 'PartyManagement/SearchParty', name: 'Party Approved', exact: true, funcId: 'party' },
    { path: '/BMS/party/pending_party_sunac_approval', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + Sun A/C Pending', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_sunac_approved', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + Sun A/C Handled', exact: true, funcId: 'party' },
    

    { path: '/BMS/party/party_admin_rejected', filePath: 'PartyManagement/SearchParty', name: 'Party Rejected', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_management_rejected', filePath: 'PartyManagement/SearchParty', name: 'Management Rejected', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_sunac_rejected', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + Sun A/C Rejected', exact: true, funcId: 'party' },

    { path: '/BMS/party/pending_for_party_sunac_cam_approval', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + CAM Pending', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_sunac_approved_cam', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + CAM Handled', exact: true, funcId: 'party' },
    { path: '/BMS/party/party_sunac_rejected_cam', filePath: 'PartyManagement/SearchParty', name: 'Party Approved + CAM Rejected', exact: true, funcId: 'party' },

    { path: '/BMS/party/party_maintenance/:party_id', filePath: 'PartyManagement/Party', name: 'Party Maintenance', funcId: 'party' },

    { path: '/BMS/party/:search_state/party_maintenance/:party_id', filePath: 'PartyManagement/Party', name: 'Party Maintenance', funcId: 'party' },


    { path: '/BMS/party/account_group_code_maintenance', filePath: 'PartyManagement/SearchAccountGroupCode', name: 'Customer Group Code Maintenance', exact: true, funcId: 'party' },
    { path: '/BMS/party/account_group_code_maintenance/new', filePath: 'PartyManagement/AccountGroupCode', name: 'Create Customer Group Code', funcId: 'party' },
    { path: '/BMS/party/account_group_code_maintenance/:customer_id?', filePath: 'PartyManagement/AccountGroupCode', name: 'Customer Group Code', funcId: 'party' },

    
    /* invoice section */

    { path: '/INV/Search', filePath: 'invoice/InvoiceSearch2', name: 'Search AP / AR', exact: true, funcId: 'm_ap' },

    { path: '/INV/InvoiceClose', filePath: 'invoice/InvoiceClose', name: 'AR / AP Pending Account Period Close (7021/23)', funcId: 'inv_closing' },
    { path: '/INV/InvoiceGenericClose', filePath: 'invoice/InvoiceGenericClose', name: 'AR / AP Pending Account Period Close', funcId: 'inv_generic_closing' },

    { path: '/INV/BlankJob', filePath: 'invoice/BlankJob', name: '703X Blank Job Listing', funcId: 'm_ap_blank_job' },
    { path: '/INV/JobMonthGP', filePath: 'invoice/InvoiceJobMonthGP', name: 'GP by Job Month Listing', funcId: 'm_ap_job_month_gp' },
    { path: '/INV/JobMonthGPSO', filePath: 'invoice/JobMonthGPSO', name: 'GP by SO', funcId: 'm_ar_gp_so' },

    //20201105 #3181 New Dashboard Card//{ path: '/INV/ap', filePath: 'Dashboard', name: 'Account Payable', exact: true, funcId: 'm_ap' },
    { path: '/INV/ap', filePath: 'invoice/Dashboard', name: 'Account Payable', exact: true, funcId: 'm_ap' },
    //20201105 #3181 New Dashboard Card//{ path: '/INV/ar', filePath: 'Dashboard', name: 'Account Receivable', exact: true, funcId: 'm_ar' },
    { path: '/INV/ar', filePath: 'invoice/Dashboard', name: 'Account Receivable', exact: true, funcId: 'm_ar' },
    { path: '/INV/GPReport', filePath: 'invoice/InvoiceReport_GP', name: 'Web Invoice GP', exact: true, funcId: 'm_analytics_inv_gp' },
    { path: '/INV/GPReport1', filePath: 'invoice/InvoiceReport_GP', name: 'GP by Job Month, Service and Mode', exact: true, funcId: 'm_analytics_inv_gp1' },
    { path: '/INV/GPReport2', filePath: 'invoice/InvoiceReport_GP', name: 'GP by POL and Office', exact: true, funcId: 'm_analytics_inv_gp2' },
    { path: '/INV/GPReport3', filePath: 'invoice/InvoiceReport_GP', name: 'GP by CBM, Container, CW and Shipment', exact: true, funcId: 'm_analytics_inv_gp3' },
    { path: '/INV/GPReport4', filePath: 'invoice/InvoiceReport_GP', name: 'GP by Invoice and Shipment Details', exact: true, funcId: 'm_analytics_inv_gp4' },
    { path: '/INV/SalesGPReport', filePath: 'invoice/InvoiceReport_SalesGP', name: 'Web Invoice Sales GP', exact: true, funcId: 'm_analytics_inv_salesgp' },

    { path: '/INV/apDetails/newbyjob/:account_code/:job_no', filePath: 'invoice/InvoiceEntry', name: 'Create New AP', exact: true,  funcId: 'inv_edit' },
    { path: '/INV/apDetails/new/:inv_id?/:tab_id?', filePath: 'invoice/InvoiceEntry', name: 'Create New AP', funcId: 'inv_edit' },
    { path: '/INV/apDetails/:inv_id/:tab_id?', filePath: 'invoice/InvoiceEntry', name: 'Invoice', exact: true, funcId: 'm_ap_search' },

    /* Charge Code Setting Section */
    { path: '/INV/chargeCodeDetails/:charge_code', filePath: 'invoice/ChargeCode/ChargeCodeEntry', name: 'Create New Charge Code', exact: true, funcId: 'm_ap_search' },

    //Quick Dump >>> SELECT * FROM SYS_MENU WHERE menu_id LIKE '%m_ap_%' and url LIKE '/INV/apSear%' ORDER BY url ASC

    { path: '/INV/apSearch/JobSearch', filePath: 'invoice/InvoicePendingCreation2', name: 'Job Search', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/arSearch/JobSearch/:stage_name?', filePath: 'invoice/InvoicePendingCreation2', name: 'Job Search', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/apSearch/PendingCreation/Local', filePath: 'invoice/InvoicePendingCreation', name: 'Local', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PendingCreation/Overseas', filePath: 'invoice/InvoicePendingCreation', name: 'Overseas', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/APRefInput', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending AP Ref to be entered', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Closed', filePath: 'invoice/InvoiceSearch2', name: 'AP Account Period Closed', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PendingPosting', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending Posting', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PendingPreparation', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending Preparation', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Posted', filePath: 'invoice/InvoiceSearch2', name: 'AP Posted', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PostedSuccess', filePath: 'invoice/InvoiceSearch2', name: 'AP Posted to SUN Success', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PostedFailure', filePath: 'invoice/InvoiceSearch2', name: 'AP Posting to SUN Failed', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/PostedPending', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending Ack. from SUN', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Prepared', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending Approval (1st level)', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Reversed', filePath: 'invoice/InvoiceSearch2', name: 'AP Reversed', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Settled', filePath: 'invoice/InvoiceSearch2', name: 'AP Settled', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Supervisor Approved', filePath: 'invoice/InvoiceSearch2', name: 'AP Pending Approval (2nd level)', exact: true, funcId: 'm_ap_search' },
    { path: '/INV/apSearch/Unsettled', filePath: 'invoice/InvoiceSearch2', name: 'AP Unsettled', exact: true, funcId: 'm_ap_search'},
    { path: '/INV/apSearch/:stage_name?', filePath: 'invoice/InvoiceSearch2', name: 'AP Search', funcId: 'm_ap_search' }, //This Line should in the bottom

    { path: '/INV/arDetails/newbyjob/:account_code/:job_no', filePath: 'invoice/InvoiceEntry', name: 'Create New AR', funcId: 'inv_edit' },
    { path: '/INV/arDetails/new/:inv_id?/:tab_id?', filePath: 'invoice/InvoiceEntry', name: 'Create New AR', funcId: 'inv_edit' },
    { path: '/INV/arDetails/:inv_id/:tab_id?', filePath: 'invoice/InvoiceEntry', name: 'Invoice', exact: true, funcId: 'm_ar_search' },

    //Quick Dump >>> SELECT * FROM SYS_MENU WHERE menu_id LIKE '%m_ar_%' and url LIKE '/INV/arSear%' ORDER BY url ASC
    { path: '/INV/arSearch/JobSearch', filePath: 'invoice/InvoicePendingCreation2', name: 'Job Search', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/JobSearch/:stage_name?', filePath: 'invoice/InvoicePendingCreation2', name: 'Job Search', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PendingCreation/Local', filePath: 'invoice/InvoicePendingCreation', name: 'Local', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PendingCreation/Overseas', filePath: 'invoice/InvoicePendingCreation', name: 'Overseas', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Closed', filePath: 'invoice/InvoiceSearch2', name: 'AR Account Period Closed', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/New', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Preparation', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PendingPosting', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Posting', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PendingPrinting', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Printing', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Posted', filePath: 'invoice/InvoiceSearch2', name: 'AR Posted', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PostedSuccess', filePath: 'invoice/InvoiceSearch2', name: 'AR Posted to SUN Success', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PostedFailure', filePath: 'invoice/InvoiceSearch2', name: 'AR Posting to SUN Failed', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/PostedPending', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Ack. from SUN', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Reversed', filePath: 'invoice/InvoiceSearch2', name: 'AR Reversed', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Settled', filePath: 'invoice/InvoiceSearch2', name: 'AR Settled', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Unsettled', filePath: 'invoice/InvoiceSearch2', name: 'AR Unsettled', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Import', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Import', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/Export', filePath: 'invoice/InvoiceSearch2', name: 'AR Pending Export', exact: true, funcId: 'm_ar_search' },
    { path: '/INV/arSearch/:stage_name?', filePath: 'invoice/InvoiceSearch2', name: 'AR Search', funcId: 'm_ar_search' }, //This Line should in the bottom
    { path: '/INV/GPTest', filePath: 'invoice/InvoiceReport_GPTest', name: 'AR Pending Export', exact: true, funcId: 'm_ar_search' },
    
    { filePath: 'AccessDeclined', name: 'Access Forbidden' },

    //please keep this as the last item!!!
    { filePath: 'NotFound', name: 'Page Not Found' }
];

export default routingTable;
